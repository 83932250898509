import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { parseMarkdowns } from "./logic"
import CommonQuestions from "src/components/CommonQuestions"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import GetStarted from "src/components/Banners/GetStarted"

const Template: FC<Props> = ({
  data: {
    allFile: { nodes: markdowns },
    file: { childYaml: texts },
  },
}) => {
  const sectionNames = texts.questions.sections
  const sections = parseMarkdowns({ markdowns, sectionNames })
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <span>{texts.header}</span>
          <h1>{texts.subHeader}</h1>
        </div>
      </header>
      <div className={styles.questionsNav}>
        <ul className={`content-wrapper ${styles.questionsNavContent}`}>
          {sections.map(section => (
            <li key={section.id}>
              <a href={`#${section.id}`}>{section.name}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.questionsContainer}>
        <div className={styles.questionsContent}>
          <CommonQuestions
            markdowns={sections}
            title={texts.questions.title}
            className={styles.faqs}
          />
        </div>
      </div>
      <div className={`content-wrapper ${styles.banner}`}>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subHeader
        questions {
          title
          sections
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/faq/questions/.+/" } }) {
      nodes {
        relativePath
        childMarkdownRemark {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default Template
