import groupBy from "lodash/groupBy"
import { Props } from "./types"
import { useContext } from "react"
import { LangContext } from "src/context/lang"

export const parseMarkdowns = ({
  markdowns,
  sectionNames,
}: {
  markdowns: Props["markdowns"]
  sectionNames: Props["sectionNames"]
}) => {
  const { lang } = useContext(LangContext)
  const sections = groupBy(
    markdowns
      .map(
        ({
          childMarkdownRemark: {
            frontmatter: { title },
            html,
          },
          relativePath,
        }) => {
          const matches = /faq\/questions\/(.+)\/(.+)\/(.+)\.md/.exec(
            relativePath,
          )

          if (!matches) {
            return null
          }

          const [, sectionId, questionId, questionLang] = matches

          if (questionLang !== lang) {
            return null
          }

          return {
            sectionId,
            questionId,
            questionTitle: title,
            questionContent: html,
          }
        },
      )
      .filter((it): it is NonNullable<typeof it> => !!it),
    ({ sectionId }) => sectionId,
  )

  return Object.keys(sections)
    .sort((a, b) => a.localeCompare(b))
    .map((key, i) => ({
      id: key,
      name: sectionNames[i],
      questions: sections[key]
        .map(({ questionId, questionTitle, questionContent }) => ({
          id: questionId,
          content: questionContent,
          title: questionTitle,
        }))
        .sort((a, b) => a.id.localeCompare(b.id)),
    }))
}
